import api from '@/api';
import router from '@/router';

export const SUPER_ADMIN = 'super-admin';
export const ADMIN = 'admin';
export const STRUCTURE_ADMIN = 'structure-admin';
export const PLACE_ADMIN = 'place-admin';
export const REGULAR = 'regular';
export const WITHOUT_ROLE = 'none';

export const CLIENT_TYPE = 'client';
export const ADMIN_TYPE = 'admin';

export const USERS_MAP = [PLACE_ADMIN, STRUCTURE_ADMIN, ADMIN, SUPER_ADMIN];

export const SYSTEM_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  BRAND_ADMIN: 'BRAND_ADMIN',
  STRUCTURE_MANAGER: 'STRUCTURE_MANAGER',
  PLACE_MANAGER: 'PLACE_MANAGER',
  EMPLOYEE: 'EMPLOYEE',
};

export const ACCEPT_BUTTON_TYPES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
};

export default {
  namespaced: true,
  state: {
    firstName: null,
    lastName: null,
    role: '',
    systemRole: '',
    type: '',
    isLogged: false,
    brand: false,
    brandDetails: null,
    id: null,
    email: null,
    homeOrganization: null,
    viewer: null,
    token: null,
    clearedBrowserData: false,
    initial: {
      role: '',
      type: '',
    },
  },
  mutations: {
    SET_USER(
      state,
      {
        firstName,
        lastName,
        role,
        brand,
        type,
        id,
        email,
        homeOrganization,
        systemRole,
      },
    ) {
      state.firstName = firstName;
      state.lastName = lastName;
      state.role = role;
      state.type = type;
      state.isLogged = true;
      state.brand = brand?.id;
      state.brandDetails = brand;
      state.id = id;
      state.email = email;
      state.homeOrganization = homeOrganization;
      state.systemRole = systemRole;
      state.initial = { role, type };
    },
    SET_TOKEN(state, payload) {
      state.token = `Bearer ${payload}`;
    },
    SET_BROWSER_CLEARED(state) {
      state.clearedBrowserData = true;
    },
    CLEAR_AUTH(state, prop) {
      state.isLogged = prop;
    },
    CLEAR_STATE(state) {
      state.firstName = null;
      state.lastName = null;
      state.role = '';
      state.type = '';
      state.isLogged = false;
      state.brand = false;
      state.brandDetails = null;
      state.id = null;
      state.email = null;
      state.homeOrganization = null;
      state.token = null;
      state.viewer = null;
    },
    SET_VIEWER(state, payload) {
      state.viewer = payload;

      if (payload) {
        state.role = state.viewer.role;
        state.type = state.viewer.type;
        return;
      }

      state.role = state.initial.role;
      state.type = state.initial.type;
    },
  },
  getters: {
    isLogged(state) {
      return state.isLogged;
    },
    role(state) {
      return state.role;
    },
    brand(state) {
      return state.brand;
    },
    isBrowserDataCleared(state) {
      return state.clearedBrowserData;
    },
    hasRights: (state) => (required) => {
      return USERS_MAP.indexOf(state.role) >= USERS_MAP.indexOf(required);
    },
    hasAdminRights(state) {
      return USERS_MAP.indexOf(state.role) >= USERS_MAP.indexOf(ADMIN);
    },
    isAuthorizedToMakeChanges:
      (state, getters) =>
      (authorizedUsers = [], includeBrandAdmin = true) => {
        const hasRequiredAdminRole = includeBrandAdmin
          ? getters.hasAdminRights
          : getters.isSystemAdmin;

        return hasRequiredAdminRole || authorizedUsers.includes(state.id);
      },
    isSuperAdmin(state) {
      return state.role === SUPER_ADMIN;
    },
    isClientUser(state) {
      return state.type === CLIENT_TYPE;
    },
    isEmployee(state) {
      return state.systemRole === SYSTEM_ROLES.EMPLOYEE;
    },
    isPlaceAdmin(state) {
      return state.role === PLACE_ADMIN;
    },
    isStructureAdmin(state) {
      return state.role === STRUCTURE_ADMIN;
    },
    isBrandAdmin(state, getters) {
      return state.role === ADMIN && getters.isClientUser;
    },
    initialRouteName: (state, getters) => (role) => {
      const ADMINS_ROUTE = 'Admins';
      const CLIENTS_ROUTE = 'Clients';
      // OBJ-2088
      // const PLACES_ROUTE = 'PlaceDashboard';
      const REPORTS_ROUTE = 'Reports';
      const ACCOUNT_NOT_CONFIGURED = 'AccountNotConfigured';
      switch (role ?? state.role) {
        case SUPER_ADMIN:
          return ADMINS_ROUTE;
        case ADMIN:
          return getters.isClientUser ? REPORTS_ROUTE : CLIENTS_ROUTE;
        // OBJ-2088
        // case PLACE_ADMIN:
        //   return PLACES_ROUTE;
        case WITHOUT_ROLE:
          return ACCOUNT_NOT_CONFIGURED;
        default:
          return REPORTS_ROUTE;
      }
    },
    isSystemAdmin(state, getters) {
      return (
        USERS_MAP.indexOf(state.role) >= USERS_MAP.indexOf(ADMIN) &&
        !getters.isClientUser
      );
    },
    homeOrganizationId: (state) => state.homeOrganization?.id || null,
    isPreviewMode: (state) => !!state.viewer,
  },
  actions: {
    async logout({ commit }) {
      api.post(`/users/logout`).then((response) => {
        if (response.status !== 204) throw new Error('Connection Error');
        else {
          commit('CLEAR_STATE');
          router.push({ name: 'Login' });
        }
      });
    },
    setToken({ commit }, payload) {
      commit('SET_TOKEN', payload);
    },
    async setUser({ commit, getters }, data) {
      commit('CLEAR_STATE');
      commit('SET_USER', data);

      const { redirect } = router.currentRoute.query;

      if (typeof redirect === 'string') {
        await router.push(redirect);
      } else {
        await router.push({ name: getters.initialRouteName() });
      }

      // Update routes depending on a role:
      router.go();
    },
    async loginUser({ dispatch }, params) {
      const response = await api.post('/users/login', params);
      if (response.status !== 201) throw new Error('Connection Error');
      else {
        const { token, user } = response.data;
        dispatch('setUser', user);
        dispatch('setToken', token);
      }
    },
    async forgotPassword(ctx, params) {
      const response = await api.post('/users/self/reset-password', params);
      if (response.status !== 201) throw new Error('Connection Error');
    },
    async newPassword(ctx, params) {
      const response = await api.post('/users/self/set-password', params);
      if (response.status !== 201) throw new Error('Connection Error');
      else {
        router.push({ name: 'Login' });
      }
    },
    setViewer({ commit }, payload) {
      commit('SET_VIEWER', payload);
    },
    clearState({ commit }) {
      commit('CLEAR_STATE');
    },
    setBrowserCleared({ commit }) {
      commit('SET_BROWSER_CLEARED');
    },
  },
};
