import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      objectFormOpened: false,
      selectedObjectType: null,
      loadingObject: false,
      forceUpdateObjects: 0,
    };
  },
  computed: {
    ...mapState('object', {
      editedContent: 'details',
    }),
    ...mapGetters('user', ['homeOrganizationId']),
    ...mapGetters('offerResources', ['offerObjectIds']),
  },
  created() {
    this.clearObjectDetails();
  },
  methods: {
    ...mapActions('object', [
      'fetchObjectDetails',
      'clearObjectDetails',
      'addObject',
      'updateObject',
    ]),
    ...mapActions('tags', ['saveTags']),
    ...mapActions('offerResources', [
      'updateObjectSelection',
      'updateObjectsAddedInOffer',
    ]),
    onAddObject(object) {
      this.updateObjectsAddedInOffer(object);

      this.updateObjectSelection({ item: object, selected: true });
    },
    onEditObject(object) {
      if (this.offerObjectIds.includes(object.id)) {
        this.updateObjectsAddedInOffer(object);

        this.updateObjectSelection({ item: object, selected: false });
        this.updateObjectSelection({ item: object, selected: true });
      } else {
        this.forceUpdateObjects++;
      }
    },
    openObjectTab() {
      this.objectFormOpened = true;
    },
    closeObjectTab() {
      this.objectFormOpened = false;
      this.selectedObjectType = null;
      this.clearObjectDetails();
    },
    async editObject(row) {
      this.loadingObject = true;

      this.openObjectTab();

      try {
        const { id } = row;
        this.selectedObjectType = row.objectType;

        await this.fetchObjectDetails(id);
      } finally {
        this.loadingObject = false;
      }
    },
    async onAdd(formData, { tagsToSave }) {
      const { data } = await this.addObject({
        ...formData,
        tags: tagsToSave.map(({ name }) => name),
      });

      this.onAddObject(data);

      this.$toast.success(this.$t('add-object-view.added-successfully'));
    },
    async onEdit(formData, { tagsToSave, tagsToDelete }) {
      const { imageUrl, id } = this.editedContent;

      if (imageUrl && !formData.imageUrl) {
        await this.deleteObjectImage(this.object.id);
      }

      const { data } = await this.updateObject({
        objectId: id,
        data: formData,
      });

      await this.saveTags({ objectId: id, tagsToDelete, tagsToSave });

      this.onEditObject(data);

      this.$toast.success(this.$t('edit-object-view.updated-successfully'));
    },
    async handleObjectSubmission(formData, tags) {
      this.objectSubmissionInProgress = true;
      this.objectSubmissionEndSuccessfully = false;

      const handler = this.editedContent ? this.onEdit : this.onAdd;

      try {
        await handler(formData, tags);

        this.closeObjectTab();

        this.objectSubmissionEndSuccessfully = true;
      } finally {
        this.objectSubmissionInProgress = false;
      }
    },
  },
};
