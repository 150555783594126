import VueStore from '@/store';

const getStructure = async (id) => {
  try {
    const { data } = await VueStore.dispatch(
      'applications/getBrandStructure',
      id,
    );

    return data;
  } catch {
    return null;
  }
};

const getUser = async (id) => {
  try {
    const { data } = await VueStore.dispatch('applications/getBrandUser', id);

    return data;
  } catch {
    return null;
  }
};

const getPlaces = (ids) => {
  if (!ids) return [];

  try {
    return Promise.all(
      ids.split(',').map(async (id) => {
        const { data } = await VueStore.dispatch(
          'applications/getBrandPlace',
          id,
        );

        return data;
      }),
    );
  } catch {
    return [];
  }
};

const getPlace = async (id) => {
  try {
    const { data } = await VueStore.dispatch('applications/getBrandPlace', id);

    return data;
  } catch {
    return null;
  }
};

const getCategory = async (id) => {
  try {
    const { data } = await VueStore.dispatch(
      'applications/getBrandCategory',
      id,
    );

    return data;
  } catch {
    return null;
  }
};

const getObjectTypes = (ids) => {
  if (!ids) return [];

  try {
    return Promise.all(
      ids
        .split(',')
        .map((id) => VueStore.dispatch('brand/fetchObjectType', id)),
    );
  } catch {
    return [];
  }
};

const getStatus = (statuses) => {
  if (!statuses) return [];

  return statuses.split(',');
};

const getTags = (tags) => {
  if (!tags) return [];

  return tags.split(',').map((tag) => ({ name: tag }));
};

const getSortBy = (sortBy) => ({ value: sortBy });

export {
  getStructure,
  getUser,
  getPlaces,
  getPlace,
  getCategory,
  getObjectTypes,
  getStatus,
  getTags,
  getSortBy,
};
