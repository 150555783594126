import { mapGetters, mapState } from 'vuex';
import { STRUCTURE_ADMIN } from '@/store/modules/user';
import difference from 'lodash.difference';

export default {
  data() {
    return {
      loadingTags: false,
      tags: [],
      tagsUploadQueue: [],
      tagsDeleteQueue: [],
    };
  },
  computed: {
    ...mapGetters('user', ['hasRights', 'isEmployee']),
    ...mapState('user', {
      userId: 'id',
    }),
    isAuthorizedToModifyTags() {
      return this.hasRights(STRUCTURE_ADMIN) || this.isEmployee;
    },
  },
  methods: {
    async removeTag(tag, action) {
      this.loadingTags = true;

      try {
        await action(tag.id);

        this.tags = this.tags?.filter(({ id }) => id !== tag.id);
      } finally {
        this.loadingTags = false;
      }
    },
    async saveTag(tag, action, updateTags = true) {
      this.loadingTags = true;

      try {
        const tagName = typeof tag === 'string' ? tag.toUpperCase() : tag.name;

        const { data } = await action(tagName);

        if (updateTags) {
          this.tags.push(data);
        }
      } finally {
        this.loadingTags = false;
      }
    },

    addTagToUploadQueue(tag) {
      const tagToUpload = typeof tag === 'string' ? { name: tag } : tag;

      if (!this.tagsUploadQueue.find(({ name }) => name == tagToUpload.name)) {
        this.tagsUploadQueue.push(tagToUpload);
      }
    },
    addTagToDeleteQueue(tag, uploaded = true) {
      if (uploaded) {
        this.tagsDeleteQueue.push(tag);
      } else {
        this.tagsUploadQueue = this.tagsUploadQueue.filter(
          ({ name }) => name !== tag.name,
        );
      }
    },
    updateQueues(savedTags, updatedTags) {
      this.tagsUploadQueue = difference(updatedTags, savedTags);
      this.tagsDeleteQueue = difference(savedTags, updatedTags);
    },
    checkDeleteTagPermission(tag) {
      return this.userId === tag.creatorId;
    },

    createNewTag(tagData, action) {
      const isTagAdded =
        tagData.options.some(
          (option) => option.name === tagData.enteredValue,
        ) || this.tags.some((tag) => tag.name === tagData.enteredValue);
      if (!isTagAdded) {
        action();
      }
    },
  },
};
