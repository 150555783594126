import { computed, defineComponent } from '@vue/composition-api';
import { useAuthUser, useUi } from '@/composables';
import DashboardHeader from '@/layouts/DashboardLayout/Components/DashboardHeader.vue';
import BrandHeader from '@/layouts/BrandLayout/Components/BrandHeader.vue';
import { ClientRouteName } from '@/router/router.constants';
export default defineComponent({
    components: { DashboardHeader, BrandHeader },
    setup() {
        const { offset } = useUi();
        const { isAdmin, isObjektoAdmin, isBrandAdmin } = useAuthUser();
        const showDashboardHeader = computed(() => {
            return (isAdmin.value && !isBrandAdmin.value) || isObjektoAdmin.value;
        });
        return { offset, showDashboardHeader, ClientRouteName };
    },
});
