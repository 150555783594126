import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import client from './modules/client';
import common from './modules/common';
import brand from './modules/brand';
import ui from './modules/ui';
import user from './modules/user';
import admin from './modules/admin';
import attachment from './modules/attachment';
import object from './modules/object';
import structures from './modules/structures';
import applications from './modules/applications';
import infiniteData from './modules/infinite-data';
import chat from './modules/chat';
import table from './modules/table';
import routes from './modules/routes';
import azure from './modules/azure';
import tags from '@/store/modules/tags';
import offer from '@/store/modules/offer';
import offerResources from '@/store/modules/offer-resources';
import reportResources from '@/store/modules/report-resources';
import map from '@/store/modules/map';

Vue.use(Vuex);

const modulesToSave = ['user', 'client', 'brand', 'ui'];

export default new Vuex.Store({
  modules: {
    common,
    ui,
    user,
    client,
    brand,
    admin,
    attachment,
    object,
    structures,
    applications,
    infiniteData,
    chat,
    table,
    routes,
    azure,
    tags,
    offer,
    offerResources,
    reportResources,
    map,
  },
  plugins: [
    createPersistedState({
      key: 'objekto',
      reducer: (persistedState) =>
        Object.entries(persistedState)
          .filter(([key]) => modulesToSave.includes(key))
          .reduce(
            (acc, [key, value]) =>
              key === 'ui'
                ? {
                    ...acc,
                    [key]: {
                      ...value,
                      sidebar: {
                        side: value.sidebar.side,
                      },
                    },
                  }
                : {
                    ...acc,
                    [key]: value,
                  },
            {},
          ),
    }),
  ],
});
