import { ReportStatus } from '@/api/requests/reports';
export const REPORT_USER_AVATARS_COUNT = 3;
export const MAX_ALLOWED_FILE_SIZE = 1000 * 1024 * 1024;
export const MAX_ALLOWED_FILES_COUNT = 10;
export const REPORT_STATUS_COLORS = {
    [ReportStatus.PENDING]: 'bg-blue-dark',
    [ReportStatus.ACTIVE]: 'bg-green',
    [ReportStatus.FINISHED]: 'bg-white border border-gray-darker',
};
export const REPORT_STATUS_MAP = {
    PENDING: 'pending',
    ACTIVE: 'active',
    FINISHED: 'finished',
};
