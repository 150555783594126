import api from '@/api';
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["ADMIN"] = "admin";
    OrganizationType["CLIENT"] = "client";
})(OrganizationType || (OrganizationType = {}));
export const getOrganizationsList = async (params) => {
    const { data } = await api.get('/organizations', {
        params,
    });
    return data;
};
export const createOrganization = async (payload) => {
    const { data } = await api.post('/organizations', payload);
    return data;
};
export const updateOrganization = async (organizationId, payload) => {
    const { data } = await api.patch(`/organizations/${organizationId}`, payload);
    return data;
};
export const deleteOrganization = async (organizationId) => {
    await api.delete(`/organizations/${organizationId}`);
};
export const addOrganizationAttachments = async (organizationId, uploadQueue) => {
    return Promise.all(uploadQueue.map((el) => api.post(`/organizations/${organizationId}/attachments`, {
        name: el.name,
        type: el.type,
        url: el.url,
    })));
};
