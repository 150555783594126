import VueStore from '@/store';
import { USERS_MAP } from '@/store/modules/user';
import { PAGE_404 } from '@/router/routes/static.route';

const userRole = function (role = VueStore.getters['user/role']) {
  return USERS_MAP.indexOf(role);
};

const grantAccess = (requiredRole) => {
  return userRole() >= userRole(requiredRole);
};

export const handleAuthenticatedUserAccess = (to, from, next) => {
  const routeRequiredRole = to.matched.find(
    (record) => record.meta.requiredRole,
  );
  const requiredRole =
    to.meta.requiredRole || routeRequiredRole?.meta.requiredRole;

  if (grantAccess(requiredRole)) {
    next();
  } else {
    next(PAGE_404);
  }
};
