import { computed, defineComponent } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import { getUserAvatar } from '@/util/avatars';
import ImageView from '@/components/ImageView/ImageView.vue';
export default defineComponent({
    components: { ImageView },
    props: {
        user: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const imgSrc = computed(() => getUserAvatar(props.user));
        const name = computed(() => getFullName(props.user));
        return { name, imgSrc };
    },
});
