import Btn from '@/components/Button/Button.vue';
import { Btn as Btn2 } from '@/components/Btn';
import PlacesSidebar from '@/views/Brand/Places/Components/Sidebar.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { fontNormal } from '@/util/formatters';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery } from '@/composables';
import { getPlacesList, } from '@/api/requests/places';
import { useAuthUser } from '@/composables/use-auth-user';
import { UserRole } from '@/api/requests/users';
import { PlacesTable } from '@/views/Brand/Places/_components/PlacesTable';
import { Icon } from '@/components/Icon';
import { placesToXlsx } from '@/util/xlsx';
import { loadAllResults } from '@/api/requests/pagination';
import { Popover } from '@/components/Popover';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';
import { PortalTarget } from '@/constants/portal';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper.vue';
import { mdiTrayArrowDown } from '@mdi/js';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { SelectionMode } from '@/store/_composables/use-resources';
import { mapId } from '@/util/common';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
import { Budgets } from '@/views/Brand/Places/_components/budgets';
import SlotTabs from '@/components/Tabs/SlotTabs.vue';
import { PlacesTabName } from '@/views/Brand/Places/_constants';
export default defineComponent({
    name: 'Places',
    components: {
        SlotTabs,
        Budgets,
        ClusteredMap,
        Icon,
        PlacesTable,
        Btn,
        Btn2,
        PlacesSidebar,
        ShowingMenu,
        FilterToggler,
        Popover,
        ContentWrapper,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const { routeQuery, selectLimit } = useRouteQuery(ctx);
        const { brand } = useBrand();
        const { isEmployee, hasRoleAccessOrHigher, isObjektoAdmin } = useAuthUser();
        const selectedPlaces = ref([]);
        const showBudgets = computed(() => brand.value?.isBudgetEnabled &&
            !isMobileView.value &&
            !isEmployee.value);
        const activeTab = ref(showBudgets.value ? PlacesTabName.BUDGETS : PlacesTabName.LIST);
        const structureAdminOrHigherAccess = computed(() => hasRoleAccessOrHigher.value(UserRole.STRUCTURE_ADMIN));
        const isAnyPlaceSelected = computed(() => selectedPlaces.value.length > 0);
        const mapQuery = computed(() => {
            const result = { ...routeQuery.value };
            if (result && brand.value) {
                delete result.page;
                delete result.limit;
            }
            return {
                ...result,
                brandId: brand.value.id,
            };
        });
        const exportMenuItems = computed(() => ({
            exportAll: {
                name: ctx.root.$t('ellipsis-menu.export-all').toString(),
                async handler() {
                    const places = await loadAllResults(getPlacesList, mapQuery.value);
                    placesToXlsx(places);
                },
            },
            exportSelected: {
                name: ctx.root.$t('ellipsis-menu.export-selected').toString(),
                disabled: !isAnyPlaceSelected.value,
                handler() {
                    placesToXlsx(selectedPlaces.value);
                },
            },
        }));
        const createReport = () => {
            const { onPlaceSelection, setPlaceSelectionMode } = usePlaceResourcesStore();
            setPlaceSelectionMode(SelectionMode.INSTANT);
            onPlaceSelection(mapId(selectedPlaces.value), selectedPlaces.value);
            setPlaceSelectionMode(SelectionMode.DELAYED);
            ctx.root.$router.push({
                name: 'Reports',
                params: {
                    addReport: true,
                },
            });
        };
        const tabs = computed(() => {
            return {
                ...(showBudgets.value && {
                    [PlacesTabName.BUDGETS]: {
                        name: PlacesTabName.BUDGETS,
                        label: ctx.root.$t('brand-places-list-view.budgets'),
                    },
                }),
                [PlacesTabName.LIST]: {
                    name: PlacesTabName.LIST,
                    label: ctx.root.$t('brand-places-list-view.list'),
                },
                [PlacesTabName.MAP]: {
                    name: PlacesTabName.MAP,
                    label: ctx.root.$t('brand-places-list-view.map'),
                },
            };
        });
        return {
            mdiTrayArrowDown,
            exportMenuItems,
            structureAdminOrHigherAccess,
            isEmployee,
            isObjektoAdmin,
            fontNormal,
            tabs,
            mapQuery,
            brand,
            selectedPlaces,
            routeQuery,
            selectLimit,
            PortalTarget,
            createReport,
            MapDataType,
            isMobileView,
            activeTab,
            PlacesTabName,
        };
    },
});
