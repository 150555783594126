<template>
  <div class="filters-container">
    <filter-group v-slot="{ resetFilters, filtersApplied }" v-model="filters">
      <div class="flex items-center justify-between">
        <h1 class="text-lg font-semibold">{{ $t('common.filter') }}</h1>
        <btn
          v-if="filtersApplied"
          theme="secondary"
          class="w-26"
          @click="resetFilters"
        >
          {{ $t('common.reset') }}
        </btn>
      </div>
      <filter-wrapper
        :label="$t('filters.structure')"
        :parameter="FILTER_PARAMS.STRUCTURE"
        :value="filters[FILTER_PARAMS.STRUCTURE]"
      >
        <select-input
          id="structureId"
          v-model="filters[FILTER_PARAMS.STRUCTURE]"
          url="structures"
          :query="{ brandId }"
          :disabled="disableFields"
          :placeholder="$t('filters.structure-placeholder')"
          option-label-name="name"
          option-value-name="id"
          class="mt-2 mb-5"
          clearable
          autocomplete
        />
      </filter-wrapper>
      <filter-wrapper
        :label="$t('filters.place')"
        :parameter="FILTER_PARAMS.PLACE"
        :value="filters[FILTER_PARAMS.PLACE]"
      >
        <select-input
          id="placeId"
          v-model="filters[FILTER_PARAMS.PLACE]"
          url="places"
          :query="{ brandId }"
          :disabled="disableFields"
          :placeholder="$t('filters.place-placeholder')"
          option-label-name="name"
          option-value-name="id"
          class="mt-2 mb-5"
          clearable
          autocomplete
        />
      </filter-wrapper>
      <filter-wrapper
        :label="$t('filters.object-type')"
        :parameter="FILTER_PARAMS.OBJECT_TYPES"
        :value="filters[FILTER_PARAMS.OBJECT_TYPES]"
      >
        <select-input
          v-model="filters[FILTER_PARAMS.OBJECT_TYPES]"
          :placeholder="$t('filters.object-type-placeholder')"
          :query="{ brandId }"
          :disabled="disableFields"
          url="object-types"
          class="my-2"
          with-search
          option-value-name="id"
          option-label-name="name"
          autocomplete
          multiple
        />
      </filter-wrapper>
      <filter-wrapper
        :label="$t('filters.tags')"
        :parameter="FILTER_PARAMS.TAGS"
        :value="filters[FILTER_PARAMS.TAGS]"
      >
        <select-input
          v-model="filters[FILTER_PARAMS.TAGS]"
          :query="{ brandId }"
          :disabled="disableFields"
          url="tags"
          icon="search"
          option-value-name="id"
          option-label-name="name"
          :placeholder="$t('filters.tags-placeholder')"
          autocomplete
          multiple
        />
      </filter-wrapper>
    </filter-group>
  </div>
</template>

<script>
import FilterGroup from '@/components/Filters/FilterGroup.vue';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import Btn from '@/components/Button/Button';
import { mapGetters } from 'vuex';
import { FILTER_PARAMS } from '@/constants/filters';
import { SelectInput } from '@/components/Inputs';

export default {
  name: 'PlacesSidebar',
  props: {
    disableFields: Boolean,
  },
  components: {
    SelectInput,
    FilterGroup,
    FilterWrapper,
    Btn,
  },
  data() {
    return {
      FILTER_PARAMS,
      filters: {
        [FILTER_PARAMS.STRUCTURE]: null,
        [FILTER_PARAMS.PLACE]: null,
        [FILTER_PARAMS.TAGS]: [],
        [FILTER_PARAMS.SEARCH]: '',
        [FILTER_PARAMS.OBJECT_TYPES]: [],
      },
    };
  },
  computed: {
    ...mapGetters('user'),
    ...mapGetters('brand', ['currentBrandId']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-container {
  @apply px-4 pt-8 h-full;
  max-height: 100%;

  @screen md {
    @apply px-6;
  }

  @screen lg {
    @apply px-10;
  }
}
</style>
