<template>
  <split-layout :left-width="57" :right-width="43">
    <template v-if="place" #leftSide>
      <div class="pb-16 pt-5">
        <div class="pr-10 h-full">
          <router-link
            :to="{ name: 'Places', params: { id: brand.id } }"
            class="text-gray-dark text-xs inline-flex items-center mb-2"
          >
            <icon :icon="mdiChevronLeft" class="text-gray-dark" />
            {{ $t('common.back') }}
          </router-link>
          <div class="mb-12 flex items-stretch">
            <div class="place-logo">
              <image-modal
                v-if="withImageModalPreview"
                :image-url="place.cdnImageUrl"
                :alt="$t('place.place-alt', { name: place.name })"
              >
                <image-view
                  :src="place.cdnImageUrl"
                  :size="ImageSize.CARD"
                  :alt="$t('place.place-alt', { name: place.name })"
                />
              </image-modal>
              <image-view
                v-else
                :src="place.cdnImageUrl"
                :size="ImageSize.CARD"
                :alt="$t('place.place-alt', { name: place.name })"
              />
            </div>
            <div>
              <h1 class="text-3xl leading-tight">{{ place.name }}</h1>
              <div class="flex mt-3">
                <div class="mr-20">
                  <div class="text-sm font-semibold flex">
                    <icon
                      :icon="mdiHomeOutline"
                      class="mt-px mr-2 text-gray-dark"
                    />
                    <div>
                      <p>
                        {{ place.address }}, {{ place.postalCode }}
                        {{ place.city }}
                      </p>
                      <p class="mb-2"></p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.place-number') }}:
                        </span>
                        {{ place.number }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.structure') }}:
                        </span>
                        {{ place.structure ? place.structure.name : '-' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.place-manager') }}:
                        </span>
                        {{ place.user ? place.user.firstName : '-' }}
                        {{ place.user ? place.user.lastName : '' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.concept') }}:
                        </span>
                        {{ place.concept ? place.concept : '-' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.type') }}:
                        </span>
                        {{ place.type ? place.type : '-' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.size') }}:
                        </span>
                        {{ place.size ? place.size : '-' }}
                      </p>
                    </div>
                  </div>
                  <div v-if="hasAdminRights" class="flex mt-5">
                    <router-link
                      :to="{
                        name: 'EditPlace',
                        params: { placeId: place.id },
                      }"
                    >
                      <icon
                        :icon="EditSvg"
                        :disabled="deletionInProgress"
                        class="mr-4 text-primary"
                      />
                    </router-link>
                    <modal-wrapper
                      v-slot="{ runWithModal }"
                      :content="getConfirmModalObject('place', place.name)"
                      class="cursor-pointer"
                    >
                      <icon
                        :icon="DeleteSvg"
                        class="mr-4 text-primary"
                        :disabled="deletionInProgress"
                        @click.native="
                          runWithModal(
                            onRemoveBtnClick,
                            getConfirmModalObject('place', place.name),
                          )
                        "
                      />
                    </modal-wrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <tabs v-if="!isEmployee" class="mt-4.5 mb-16">
            <tab :name="$t('place-details-view.documents')" selected>
              <upload-list
                type="document"
                :list="attachments.document"
                read-only
                class="mt-6"
              />
            </tab>
            <tab :name="$t('place-details-view.multimedia')">
              <upload-list
                type="multimedia"
                :list="attachments.multimedia"
                read-only
                class="mt-6"
              />
            </tab>
          </tabs>
          <div class="flex flex-col gap-4 items-center justify-center">
            <router-link
              :to="{
                name: 'PlaceDashboard',
                params: { placeId: place.id, id: brand.id },
              }"
            >
              <btn theme="primary">
                {{ $t('place-details-view.more-details') }}
              </btn>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template #rightSide>
      <div v-if="place" class="pl-12 pr-4 xxl:pl-16 xxl:pr-6 py-16">
        <clustered-map
          :data-sets="[
            {
              name: 'places',
              url: `places/${place.id}`,
              query: { isPositionDefined: true },
              type: MapDataType.PLACE,
              hidePreviewButton: true,
              labelAccessor: ['number'],
              labelFormatter: (label) => `#${label}`,
            },
          ]"
        />
        <p>
          <span class="info-line text-sm">
            {{ $t('place-details-view.coordinates') }}:
          </span>
          <b class="text-gray-darker text-sm">
            {{ convertDMS(place.lat, place.lng) }}
          </b>
        </p>
        <h4 class="mt-3">{{ $t('place-details-view.tags') }}:</h4>
        <ul class="tag-list">
          <template v-if="place.tags.length">
            <tag
              v-for="(tag, index) in place.tags"
              :key="index"
              :delete-btn="false"
            >
              {{ tag.name }}
            </tag>
          </template>
          <span v-else>-</span>
        </ul>
      </div>
    </template>
  </split-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Icon } from '@/components/Icon';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import UploadList from '@/components/Uploader/UploadList.vue';
import SplitLayout from '@/layouts/SplitLayout.vue';
import ImageModal from '@/components/Modal/ImageModal.vue';
import VueStore from '@/store';
import ModalWrapper from '@/components/Modal/ModalWrapper';
import { convertDMS } from '@/util/formatters';
import Tag from '@/components/Tag/Tag';
import ConfirmModalMixin from '@/components/Modal/mixins/ConfirmModal';
import { ImageView, ImageSize } from '@/components/ImageView';
import { mdiChevronLeft, mdiHomeOutline } from '@mdi/js';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
import Btn from '@/components/Button/Button.vue';

export default {
  name: 'PlaceDetails',
  components: {
    Btn,
    ClusteredMap,
    Tag,
    Icon,
    Tabs,
    Tab,
    SplitLayout,
    UploadList,
    ImageModal,
    ModalWrapper,
    ImageView,
  },
  mixins: [ConfirmModalMixin],
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    withImageModalPreview: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiChevronLeft,
      mdiHomeOutline,
      EditSvg,
      DeleteSvg,
      deletionInProgress: false,
      ImageSize,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      place: 'place',
    }),
    ...mapState('attachment', ['attachments']),
    ...mapGetters('user', ['hasAdminRights', 'isEmployee']),
    computedQuery() {
      return { ...this.query, limit: 30 };
    },
    hasCoordinates() {
      return this.place.lat !== null && this.place.lng !== null;
    },
    mapOptions() {
      const { lat, lng } = this.place;

      return {
        ...(this.hasCoordinates && {
          center: {
            lat,
            lng,
          },
        }),
        zoom: this.hasCoordinates ? 12 : 4,
      };
    },
  },
  async beforeRouteEnter(to, from, next) {
    const id = to.params.placeId;
    const promises = [VueStore.dispatch('brand/fetchBrandPlaceDetails', id)];

    if (!VueStore.getters['user/isEmployee']) {
      promises.push([
        VueStore.dispatch('attachment/fetchAttachments', {
          id,
          target: 'places',
          type: 'document',
        }),
        VueStore.dispatch('attachment/fetchAttachments', {
          id,
          target: 'places',
          type: 'multimedia',
        }),
      ]);
    }
    await Promise.all(promises);

    next();
  },
  methods: {
    ...mapActions('common', ['delete']),
    convertDMS,
    onPlaceEdit(id) {
      this.$router.push({ name: 'EditBrandPlace', params: { placeId: id } });
    },
    async onRemoveBtnClick() {
      this.deletionInProgress = true;
      try {
        await this.delete({ type: 'places', id: this.place.id });

        this.$router.push({ name: 'Places' });

        this.$toast.success(
          this.$t('brand-places-list-view.removed-successfully'),
        );
      } finally {
        this.deletionInProgress = false;
      }
    },
  },
  setup() {
    return { MapDataType };
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 480px;
  @apply rounded overflow-hidden;
}

.spinner-area {
  @apply py-48 w-full flex justify-center;
}

.place-logo {
  @apply w-60 h-48 mr-8 bg-white border border-gray-light rounded flex items-center justify-center overflow-hidden;
  img {
    @apply object-cover w-full h-full;
  }
}

.info-line {
  @apply text-gray-darker font-normal inline-block w-30 mr-3;
}

.container-fluid {
  @apply min-h-screen;
}

.invoice-info {
  p {
    @apply leading-relaxed;
  }
}

.tag-list {
  @apply flex flex-wrap mt-2;
}
</style>
