import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery, useSort } from '@/composables';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { GenericTable } from '@/components/Table/__new__';
import { Checkbox } from '@/components/Inputs';
import { useSelection } from '@/composables/use-selection';
import { TableActions, TablePagination } from '@/views/_components/Table';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import PlacePreview from '@/views/Brand/Places/PlacePreview.vue';
import { usePlaceActions } from '@/views/Brand/Places/_composables/use-place-actions';
import { Debounce } from '@/constants/debounce';
import { useExpandDetails } from '@/composables';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
export default defineComponent({
    components: {
        TableLoader,
        TablePagination,
        TableActions,
        Checkbox,
        FetchData,
        TableDisplay: GenericTable(),
        PlacePreview,
        ExpandButton,
    },
    props: {
        query: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { changeState, selectedEntries } = useSelection('id');
        const selectAll = ref(false);
        const onSelectAll = (results) => {
            changeState(results, selectAll.value);
        };
        watch(selectedEntries, (value) => ctx.emit('input', value), {
            deep: true,
        });
        const { routeQuery, updateQueryParameter } = useRouteQuery(ctx);
        const { sortConfig, sortBy, sort, setSortBy } = useSort();
        watch(routeQuery, (value) => {
            if (value.sortBy && sortBy !== value.sortBy)
                setSortBy(value.sortBy);
        });
        watch(sortBy, (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: 'sortBy',
                value: { value },
            });
        });
        const { isMobileView } = useMobileView();
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'expandButton',
                },
                {
                    key: 'number',
                    label: ctx.root.$t('brand-places-list-view.number').toString(),
                    cellClass: 'font-bold',
                    sortable: true,
                    isNumber: true,
                },
                {
                    key: 'name',
                    label: ctx.root.$t('brand-places-list-view.name').toString(),
                    sortable: true,
                    cellClass: 'font-bold',
                },
                ...(isMobileView.value
                    ? []
                    : [
                        {
                            key: 'address',
                            label: ctx.root.$t('brand-places-list-view.address').toString(),
                            formatter: (value, row) => `${row.city}, ${value}`,
                            gridColumn: '1fr',
                        },
                        {
                            key: 'structure',
                            label: ctx.root
                                .$t('brand-places-list-view.structure')
                                .toString(),
                            formatter: (structure) => structure.name,
                            hideInMobile: true,
                            sortable: true,
                            cellClass: 'font-bold',
                        },
                    ]),
                {
                    key: 'actions',
                    cellClass: isMobileView.value ? 'pl-0 pr-4' : undefined,
                },
            ];
        });
        const placesQuery = computed(() => {
            return {
                ...routeQuery.value,
                brandId: brand.value?.id,
                ...props.query,
            };
        });
        const { getPlaceActions, listUpdatesCounter } = usePlaceActions(ctx);
        const { toggleExpandedItems: toggleExpandPlaceDetails, isExpanded: isPlaceDetailsExpanded, } = useExpandDetails((place) => place.id);
        return {
            Debounce,
            getPlaceActions,
            listUpdatesCounter,
            placesQuery,
            columns,
            changeState,
            selectedEntries,
            selectAll,
            onSelectAll,
            sort,
            sortConfig,
            isPlaceDetailsExpanded,
            toggleExpandPlaceDetails,
        };
    },
});
