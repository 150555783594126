<template>
  <div>
    <div class="w-2/5 mt-8">
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="title"
          :placeholder="$t('forms.name')"
          :label="$t('forms.set-name')"
          :error-message="errors[0]"
          required
          :disabled="loading"
          @input="$emit('set-values', formValues)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-if="formType === FORM_TYPE.OBJECT"
        v-slot="{ errors }"
        rules="required"
      >
        <select-input
          id="object-type-select"
          v-model="objectType"
          url="object-types"
          :query="{ brandId, hasAssignedForm: false }"
          option-label-name="name"
          option-value-name="id"
          :placeholder="$t('forms.object-type')"
          :label="$t('forms.set-object-type')"
          :error-message="errors[0]"
          :disabled="loading"
          class="mt-4"
          required
          autocomplete
          @input="$emit('set-values', formValues)"
        />
      </ValidationProvider>
    </div>
    <div class="w-2/5 mt-6 relative">
      <ValidationProvider v-if="mode === FORM_MODE.EDIT" v-slot="{ errors }">
        <select-input
          v-if="categories && category"
          :value="category"
          class="mb-4"
          :label="$t('forms.category')"
          :options="categories.results"
          option-label-name="name"
          option-value-name="id"
          :error-message="errors[0]"
          required
          disabled
        />
      </ValidationProvider>
      <template v-if="formType === FORM_TYPE.REPORT">
        <select-input
          id="decision-makers-select"
          v-model="decisionMakers"
          url="users"
          :query="{ brandId }"
          :label="$t('forms.decision-makers')"
          :placeholder="$t('common.select')"
          option-value-name="id"
          class="mb-2"
          :disabled="loading"
          multiple
          autocomplete
          clearable
          @input="$emit('set-values', formValues)"
        >
          <template #selection="{ selection }">
            <span>{{ selection.firstName }} {{ selection.lastName }}</span>
          </template>

          <template #option="{ option }">
            <span>{{ option.firstName }} {{ option.lastName }}</span>
          </template>
        </select-input>
        <select-input
          id="employees-select"
          v-model="employees"
          url="users"
          :query="employeesQuery"
          :label="$t('forms.employee-users')"
          :placeholder="$t('common.select')"
          option-value-name="id"
          :disabled="loading"
          clearable
          autocomplete
          multiple
          @input="$emit('set-values', formValues)"
        >
          <template #selection="{ selection }">
            <span>{{ selection.firstName }} {{ selection.lastName }}</span>
          </template>

          <template #option="{ option }">
            <span>{{ option.firstName }} {{ option.lastName }}</span>
          </template>
        </select-input>
        <div class="flex flex-col mt-3.5 gap-5">
          <div class="flex">
            <checkbox
              v-model="mustContainObjects"
              @input="$emit('set-values', formValues)"
            />
            <p class="mr-4">{{ $t('forms.contain-objects') }}</p>
          </div>
          <div class="flex">
            <checkbox
              v-model="mustContainPlaces"
              @input="$emit('set-values', formValues)"
            />
            <p class="mr-4">{{ $t('forms.contain-places') }}</p>
          </div>
        </div>
      </template>
    </div>

    <form-creator
      v-model="components"
      :disabled="loading"
      @input="$emit('set-values', formValues)"
    />
  </div>
</template>

<script>
import FormCreator from '@/views/Brand/Settings/Forms/Components/FormCreator';
import { ValidationProvider } from 'vee-validate';
import { TextInput, SelectInput, Checkbox } from '@/components/Inputs';
import extendValidationRules from '@/mixins/extendValidationRules';
import { FORM_MODE, FORM_TYPE } from '@/constants/form';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getPropertyArray } from '@/util/formatters';
import cloneDeep from 'lodash.clonedeep';
import { SYSTEM_ROLES } from '@/store/modules/user';

export default {
  components: {
    FormCreator,
    SelectInput,
    ValidationProvider,
    TextInput,
    Checkbox,
  },
  mixins: [extendValidationRules],
  props: {
    formType: {
      type: String,
      required: true,
      validate: (type) => Object.values(FORM_TYPE).includes(type),
    },
    mode: {
      type: String,
      required: true,
      validate: (type) => Object.values(FORM_MODE).includes(type),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_TYPE,
      FORM_MODE,
      components: [],
      title: '',
      objectType: null,
      decisionMakers: [],
      employees: [],
      canContainObjects: true,
      mustContainObjects: false,
      mustContainPlaces: false,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapState('brand', {
      categories: 'categories',
      currentCategory: 'currentCategory',
      currentForm: 'currentForm',
      currentOfferForm: 'currentOfferForm',
    }),
    brandId() {
      return this.currentBrandId(this.$route);
    },
    category() {
      return this.categories?.results?.find((item) => {
        return item.id === this.currentCategory?.id;
      });
    },
    formValues() {
      return {
        title: this.title,
        ...(this.decisionMakers.length && {
          decisionMakersIds: getPropertyArray(this.decisionMakers, 'id'),
        }),
        ...(this.employees.length && {
          suggestedEmployeesIds: getPropertyArray(this.employees, 'id'),
        }),
        mustContainObjects: this.mustContainObjects,
        mustContainPlaces: this.mustContainPlaces,
        components: cloneDeep(this.components),
        ...(this.formType === FORM_TYPE.OBJECT && {
          objectTypeId: this.objectType?.id,
        }),
      };
    },
    employeesQuery() {
      return {
        systemRoles: [SYSTEM_ROLES.EMPLOYEE],
        accessToBrandId: this.brandId,
      };
    },
  },
  watch: {
    currentForm: {
      async handler(form) {
        if (form) await this.setDefaultValues();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('brand', ['fetchObjectType']),
    async setDefaultValues() {
      const {
        components,
        title,
        decisionMakers,
        suggestedEmployees,
        mustContainObjects,
        mustContainPlaces,
        type,
        objectTypeId,
      } = this.currentForm;

      this.components = cloneDeep(components);
      this.title = title;
      this.decisionMakers = [...decisionMakers];
      this.employees = [...suggestedEmployees];
      this.mustContainObjects = mustContainObjects;
      this.mustContainPlaces = mustContainPlaces;

      if (type === FORM_TYPE.OBJECT) {
        this.objectType = await this.fetchObjectType(objectTypeId);
      }

      this.$emit('set-values', this.formValues);
    },
  },
};
</script>
