const getPriceRange = (monthlyPriceFrom, monthlyPriceTo) => {
    const priceFrom = monthlyPriceFrom ? parseFloat(monthlyPriceFrom) : undefined;
    const priceTo = monthlyPriceTo ? parseFloat(monthlyPriceTo) : undefined;
    return {
        monthlyPriceFrom: priceFrom,
        monthlyPriceTo: priceTo,
    };
};
export const getObjectListQuery = ({ structure, place, internalStatus, tags, monthlyPriceFrom, monthlyPriceTo, q, }) => {
    return {
        anyDescendantOfStructureId: structure?.id,
        placeIds: place ? [place?.id] : undefined,
        internalStatus: internalStatus || undefined,
        q: q || undefined,
        tags: tags?.map(({ name }) => name),
        ...getPriceRange(monthlyPriceFrom, monthlyPriceTo),
    };
};
export const getPlaceListQuery = ({ user, structure, tags, q, }) => ({
    userId: user?.id,
    anyDescendantOfStructureId: structure?.id,
    tags: tags?.map(({ name }) => name),
    q: q || undefined,
});
