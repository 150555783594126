<template>
  <div class="text-sm w-full">
    <div class="flex justify-between md:items-center gap-4 md: gap-2 pb-4">
      <p class="text-2xl">{{ $t('object-details-view.details') }}</p>
      <StarToggle
        :value="object.isImportant"
        badge
        @input="changeImportanceStatus($event)"
      />
    </div>
    <div class="mb-5">
      <p>
        <span class="info-line">
          {{ $t('object-details-view.object-number') }}:
        </span>
        {{ object.internalNumber }}
      </p>
      <p class="flex">
        <span class="info-line">
          {{ $t('object-details-view.place-number') }}:
        </span>
        <span v-if="object.place">
          <place-with-popover
            v-if="placePreview === ObjectDetailsPlacePreview.POPOVER"
            show-number
            :show-name="false"
            class="ml-1"
            :place="object.place"
            display-property="number"
          />
          <span v-if="placePreview === ObjectDetailsPlacePreview.TEXT">
            {{ object.place.number }}
          </span>
          <router-link
            v-if="placePreview === ObjectDetailsPlacePreview.LINK"
            :to="{
              name: 'PlaceDetails',
              params: { placeId: object.place.id },
            }"
            class="text-primary font-bold hover:underline"
          >
            {{ object.place.number }}
          </router-link>
        </span>
        <span v-else>-</span>
      </p>
      <p>
        <span class="info-line">
          {{ $t('object-details-view.coordinates') }}:
        </span>
        {{ object.lat }}, {{ object.lng }}
      </p>
      <p>
        <span class="info-line">{{ $t('object-details-view.link') }}:</span>
        <a
          v-if="object.videoUrl"
          :href="object.videoUrl"
          class="text-links"
          target="_blank"
        >
          {{ object.videoUrl }}
        </a>
        <span v-else>-</span>
      </p>
    </div>

    <custom-form-preview
      v-if="object.formVersion"
      :components="object.formVersion.components"
      :values="object.values"
      item-class-name="w-full text-xs my-1 sm:flex"
      label-class-name="text-gray-darker font-normal inline-block min-w-28 mr-3"
    />

    <ul class="tag-list">
      <tag
        v-for="tag in tags"
        :key="tag.id"
        delete-btn
        @delete="onDeleteTag(tag)"
      >
        {{ tag.name }}
      </tag>
    </ul>
  </div>
</template>

<script>
import { convertDMS } from '@/util/formatters';
import { mapActions } from 'vuex';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import PlaceWithPopover from '@/views/_components/PlaceWithPopover';
import Tag from '@/components/Tag/Tag';
import StarToggle from '@/components/StarToggle/StarToggle.vue';
import { updateObjectImportant } from '@/api/requests/objects';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';

export default {
  components: {
    StarToggle,
    PlaceWithPopover,
    CustomFormPreview,
    Tag,
  },
  props: {
    object: {
      type: Object,
      default() {
        return {};
      },
    },
    placePreview: {
      type: String,
      default: ObjectDetailsPlacePreview.POPOVER,
    },
  },
  mixins: [manageTagsMixin],
  methods: {
    convertDMS,
    ...mapActions('tags', ['deleteObjectTag']),
    ...mapActions('object', ['fetchObjectDetails']),
    onDeleteTag(tag) {
      this.removeTag(tag, (tagId) =>
        this.deleteObjectTag({ objectId: this.object.id, tagId }),
      );
    },
    async changeImportanceStatus(value) {
      await updateObjectImportant(this.object.id, { isImportant: value });
      await this.fetchObjectDetails(this.object.id);
    },
  },
  setup() {
    return { ObjectDetailsPlacePreview };
  },
  mounted() {
    this.tags = [...this.object.tags];
  },
};
</script>

<style lang="scss" scoped>
.info-line {
  @apply text-gray-darker font-normal inline-block min-w-28 mr-3;
}

.tag-list {
  @apply flex flex-wrap mt-4;
}
</style>
