import api from '@/api';
export var SystemRole;
(function (SystemRole) {
    SystemRole["SUPER_ADMIN"] = "SUPER_ADMIN";
    SystemRole["ADMIN"] = "ADMIN";
    SystemRole["BRAND_ADMIN"] = "BRAND_ADMIN";
    SystemRole["STRUCTURE_MANAGER"] = "STRUCTURE_MANAGER";
    SystemRole["PLACE_MANAGER"] = "PLACE_MANAGER";
    SystemRole["EMPLOYEE"] = "EMPLOYEE";
})(SystemRole || (SystemRole = {}));
export var UserRole;
(function (UserRole) {
    UserRole["SUPER_ADMIN"] = "super-admin";
    UserRole["ADMIN"] = "admin";
    UserRole["STRUCTURE_ADMIN"] = "structure-admin";
    UserRole["PLACE_ADMIN"] = "place-admin";
    UserRole["REGULAR"] = "regular";
    UserRole["NONE"] = "none";
})(UserRole || (UserRole = {}));
export const getUsersList = async (params) => {
    const { data } = await api.get('users', { params });
    return data;
};
export const createClientUser = async (payload) => {
    const { data } = await api.post('/users/clients', payload);
    return data;
};
export const updateClientUser = async (userId, payload) => {
    const { data } = await api.patch(`/users/clients/${userId}`, payload);
    return data;
};
export const getSingleUser = async (userId) => {
    const { data } = await api.get(`/users/${userId}`);
    return data;
};
export const getNotificationSettings = async () => {
    const { data } = await api.get('users/email-notification-setting');
    return data;
};
export const updateNotificationSetting = async (payload) => {
    const { data } = await api.patch('users/email-notification-setting', payload);
    return data;
};
export const createUserVacation = async (payload) => {
    const { data } = await api.post('users/vacation', payload);
    return data;
};
export const getUserVacation = async () => {
    const { data } = await api.get('users/vacation');
    return data;
};
