import FilterGroup from '@/components/Filters/FilterGroup.vue';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import { PLACE_ADMIN } from '@/store/modules/user';
import { TextInput, SelectInput } from '@/components/Inputs';
import StatusSelect from '@/views/Brand/Objects/Forms/Components/StatusSelect.vue';
import { FILTER_PARAMS, TAG_DISPLAY_FORMATTERS } from '@/constants/filters';
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/composables';
import { Btn } from '@/components/Button';
import { useObjectFilters } from '@/views/Brand/Objects/_composables/use-object-filters';
import { TAG_STYLE, TAG_THEME } from '@/components/Tag/tag.constants';
import OverflowWrapper from '@/components/OverflowWrapper/OverflowWrapper.vue';
import Tag from '@/components/Tag/Tag.vue';
import Checkbox from '@/components/Inputs/Checkbox/Checkbox.vue';
export default defineComponent({
    name: 'ObjectsSidebar',
    props: {
        forceUpdate: {
            type: [Boolean, Number],
            default: false,
        },
    },
    components: {
        Checkbox,
        StatusSelect,
        FilterGroup,
        OverflowWrapper,
        Tag,
        FilterWrapper,
        Btn,
        TextInput,
        SelectInput,
    },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { filters, filterTags, removeActiveFilter, validators, errors } = useObjectFilters(ctx);
        return {
            brandId: brand.value.id,
            filters,
            filterTags,
            removeActiveFilter,
            validators,
            errors,
            TAG_THEME,
            TAG_STYLE,
            TAG_DISPLAY_FORMATTERS,
            FILTER_PARAMS,
            PLACE_ADMIN,
        };
    },
});
