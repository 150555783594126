import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { computed } from '@vue/composition-api';
import { ADMIN_TYPE } from '@/store/modules/user';
const { useState, useGetters, useActions } = createNamespacedHelpers('user');
export const useAuthUser = () => {
    const { logout } = useActions(['logout']);
    const { id, type, systemRole, firstName, lastName } = useState([
        'id',
        'type',
        'systemRole',
        'firstName',
        'lastName',
    ]);
    const { hasAdminRights, isEmployee, hasRights, homeOrganizationId, initialRouteName, isBrandAdmin, } = useGetters([
        'hasAdminRights',
        'isEmployee',
        'hasRights',
        'homeOrganizationId',
        'initialRouteName',
        'isBrandAdmin',
    ]);
    const isObjektoAdmin = computed(() => {
        return hasAdminRights.value && type.value === ADMIN_TYPE;
    });
    const hasRole = (role) => {
        return systemRole.value === role;
    };
    return {
        firstName,
        lastName,
        authUserId: id,
        isAdmin: hasAdminRights,
        isEmployee,
        hasRoleAccessOrHigher: hasRights,
        isObjektoAdmin,
        isBrandAdmin,
        hasRole,
        logout,
        homeOrganizationId,
        initialRouteName,
    };
};
