import { computed, defineComponent, ref } from '@vue/composition-api';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import PlaceDetailsModal from '@/views/_components/PlaceDetailsModal.vue';
export default defineComponent({
    components: {
        PlaceDetailsModal,
        Tooltip,
    },
    props: {
        place: {
            type: Object,
            required: true,
        },
        displayProperty: {
            type: String,
            default: 'name',
        },
        showNumber: Boolean,
        showName: {
            type: Boolean,
            default: true,
        },
        showDashboard: Boolean,
    },
    setup(props) {
        const showModal = ref(false);
        const activePlace = ref(null);
        const label = computed(() => `${props.place.address}, ${props.place.postalCode || ''} ${props.place.city}`);
        const handlePlaceClick = (place) => {
            activePlace.value = place;
            showModal.value = true;
        };
        return {
            label,
            showModal,
            activePlace,
            handlePlaceClick,
        };
    },
});
