import { utils, writeFile } from 'xlsx';
import { getFullName } from '@/util/user';
const getXlsx = (fileName, headers, rows) => {
    const headerLabels = headers.map(({ label }) => label);
    const parsedRows = rows.map((row) => headers.map((header) => row[header.key]));
    const sheet = utils.aoa_to_sheet([headerLabels, ...parsedRows]);
    const book = utils.book_new();
    utils.book_append_sheet(book, sheet, '1');
    writeFile(book, `${fileName}.xlsx`);
};
export const placesToXlsx = (places) => {
    const headers = [
        { key: 'brandName', label: 'BRAND NAME' },
        { key: 'structureName', label: 'STRUCTURE NAME' },
        { key: 'placeManagerName', label: 'PLACE MANAGER NAME' },
        { key: 'placeManagerEmail', label: 'PLACE MANAGER EMAIL' },
        { key: 'placeManagerPhone', label: 'PLACE MANAGER PHONE' },
        { key: 'systemId', label: 'SYSTEM ID' },
        { key: 'number', label: 'PLACE NUMBER' },
        { key: 'name', label: 'PLACE NAME' },
        { key: 'city', label: 'CITY' },
        { key: 'address', label: 'ADDRESS' },
        { key: 'postalCode', label: 'POSTAL CODE' },
        { key: 'lat', label: 'LAT' },
        { key: 'lng', label: 'LNG' },
        { key: 'concept', label: 'CONCEPT' },
        { key: 'type', label: 'TYPE' },
        { key: 'size', label: 'SIZE' },
        { key: 'tags', label: 'TAGS' },
    ];
    const rows = places.map((place) => ({
        brandName: place.brand.name,
        structureName: place.structure.name,
        placeManagerName: place.user ? getFullName(place.user) : '',
        placeManagerEmail: place.user?.email || '',
        placeManagerPhone: place.user?.phone || '',
        systemId: place.id,
        number: place.number,
        name: place.name,
        city: place.city || '',
        address: place.address || '',
        postalCode: place.postalCode || '',
        lat: place.lat?.toString() || '',
        lng: place.lng?.toString() || '',
        concept: place.concept || '',
        type: place.type || '',
        size: place.size || '',
        tags: place.tags.map((tag) => tag.name).join(', '),
    }));
    const brandName = places[0].brand?.name || '';
    const timestamp = new Date().toISOString().split('.')[0].replace('T', '_');
    getXlsx(`${brandName}_places_${timestamp}`, headers, rows);
};
export const objectsToXlsx = (objects) => {
    const headers = [
        { key: 'brandName', label: 'BRAND NAME' },
        { key: 'systemId', label: 'SYSTEM ID' },
        { key: 'internalStatus', label: 'INTERNAL STATUS' },
        { key: 'internalNumber', label: 'INTERNAL NUMBER' },
        { key: 'tags', label: 'TAGS' },
        { key: 'placeNumber', label: 'PLACE NUMBER' },
        { key: 'placeName', label: 'PLACE NAME' },
        { key: 'objectTypeName', label: 'OBJECT TYPE NAME' },
        { key: 'city', label: 'CITY' },
        { key: 'address', label: 'ADDRESS' },
        { key: 'postalCode', label: 'POSTAL CODE' },
        { key: 'lat', label: 'LAT' },
        { key: 'lng', label: 'LNG' },
        { key: 'localization', label: 'LOCALIZATION' },
        { key: 'monthlyPrice', label: 'MONTHLY PRICE' },
    ];
    const rows = objects.map((object) => ({
        brandName: object.brand.name,
        systemId: object.id,
        internalStatus: object.internalStatus,
        internalNumber: object.internalNumber.toString(),
        tags: object.tags.map((tag) => tag.name).join(', '),
        placeNumber: object.place.number,
        placeName: object.place.name,
        objectTypeName: object.objectType.name,
        city: object.city || '',
        address: object.address || '',
        postalCode: object.postalCode || '',
        lat: object.lat?.toString() || '',
        lng: object.lng?.toString() || '',
        localization: object.localization || '',
        monthlyPrice: object.monthlyPrice?.toString() || '',
    }));
    const brandName = objects[0].brand?.name || '';
    const timestamp = new Date().toISOString().split('.')[0].replace('T', '_');
    getXlsx(`${brandName}_objects_${timestamp}`, headers, rows);
};
