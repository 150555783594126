var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [(_vm.brand)?_c('form',{ref:"form",staticClass:"form py-1 mb-8",class:{ 'pb-48': !_vm.inOffer },attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('image-uploader',{staticClass:"mt-6",class:{ fill: _vm.imageUrl },attrs:{"label":_vm.$t('object-form.logo-label')},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}}),(!_vm.inOffer && _vm.enableObjectTypeUpdate)?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{staticClass:"my-4",attrs:{"id":"object-type","url":"object-types","query":Object.assign({}, {brandId: _vm.brand.id},
            (!!_vm.content && { hasAssignedForm: true })),"label":_vm.$t('object-form.object-type'),"placeholder":_vm.$t('object-form.object-type-placeholder'),"error-message":errors[0],"option-label-name":"name","option-value-name":"id","required":"","autocomplete":""},model:{value:(_vm.selectedObjectType),callback:function ($$v) {_vm.selectedObjectType=$$v},expression:"selectedObjectType"}})]}}],null,true)})]:_vm._e(),(_vm.content)?_c('text-input',{attrs:{"value":_vm.content.internalNumber,"disabled":"","label":_vm.$t('object-form.object-number')}}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('select-input',{staticClass:"my-4",attrs:{"id":"place","url":"places","label":_vm.$t('object-form.place'),"placeholder":_vm.$t('object-form.place-placeholder'),"error-message":errors[0],"option-label-name":"name","option-value-name":"id","query":Object.assign({}, {brandId: _vm.brand.id},
          (_vm.offerReport ? { reportId: _vm.offerReport } : {})),"required":"","autocomplete":"","clearable":""},model:{value:(_vm.selectedPlace),callback:function ($$v) {_vm.selectedPlace=$$v},expression:"selectedPlace"}})]}}],null,true)}),_c('Localization',{model:{value:(_vm.localization),callback:function ($$v) {_vm.localization=$$v},expression:"localization"}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-4",attrs:{"label":_vm.$t('object-form.address'),"placeholder":_vm.$t('object-form.address-placeholder-1'),"error-message":errors[0],"disabled":_vm.isLoading || _vm.onProperty,"required":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)}),_c('div',{staticClass:"flex"},[_c('PostalCode',{attrs:{"is-loading":_vm.isLoading,"disabled":_vm.onProperty},model:{value:(_vm.form.postalCode),callback:function ($$v) {_vm.$set(_vm.form, "postalCode", $$v)},expression:"form.postalCode"}}),_c('ValidationProvider',{staticClass:"w-3/4",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-input',{staticClass:"my-4",attrs:{"placeholder":_vm.$t('object-form.address-placeholder-3'),"error-message":errors[0],"disabled":_vm.isLoading || _vm.onProperty,"required":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_c('Coordinates',{attrs:{"value":_vm.coordinates,"is-loading":_vm.isLoading,"clear":_vm.clear,"helper-text":!_vm.inOffer},on:{"input":function($event){return _vm.$emit('set-coordinates', $event)},"set-tab":function($event){return _vm.$emit('set-tab', $event)}}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-input',{staticClass:"my-4",attrs:{"label":_vm.$t('object-form.price'),"placeholder":_vm.$t('object-form.price-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"type":"number","required":""},model:{value:(_vm.form.monthlyPrice),callback:function ($$v) {_vm.$set(_vm.form, "monthlyPrice", _vm._n($$v))},expression:"form.monthlyPrice"}})]}}],null,true)}),(!_vm.inOffer)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('status-select',{staticClass:"my-4",attrs:{"label":_vm.$t('object-form.internal-status'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.internalStatus),callback:function ($$v) {_vm.internalStatus=$$v},expression:"internalStatus"}})]}}],null,true)}):_vm._e(),_c('custom-object-form',{attrs:{"content":_vm.content,"is-loading":_vm.isLoading,"clear":_vm.clear,"enable-object-type-update":_vm.enableObjectTypeUpdate,"object-type":_vm.selectedObjectType || _vm.objectType},on:{"get-form-values":function($event){_vm.objectTypeForm = $event}}}),_c('text-input',{staticClass:"my-4",attrs:{"label":_vm.$t('object-form.link'),"placeholder":_vm.$t('object-form.link-placeholder'),"disabled":_vm.isLoading},model:{value:(_vm.videoUrl),callback:function ($$v) {_vm.videoUrl=$$v},expression:"videoUrl"}}),_c('div',{staticClass:"flex mt-4"},[_c('select-input',{attrs:{"id":"objects-tags","url":"tags","label":_vm.$t('place-preview.tags'),"placeholder":_vm.$t('place-preview.tags-placeholder'),"option-label-name":"name","option-value-name":"id","autocomplete":"","multiple":"","clearable":""},on:{"enter":_vm.handleEnter},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),(_vm.localization === _vm.OBJECT_LOCALIZATION.OUTSIDE)?_c('switch-input',{staticClass:"my-5",attrs:{"label":_vm.$t('object-form.direction-on-map'),"label-position":_vm.LabelPosition.RIGHT},model:{value:(_vm.showDirectionAngle),callback:function ($$v) {_vm.showDirectionAngle=$$v},expression:"showDirectionAngle"}}):_vm._e(),(_vm.showDirectionAngle)?_c('angle-picker',{model:{value:(_vm.form.directionAngle),callback:function ($$v) {_vm.$set(_vm.form, "directionAngle", $$v)},expression:"form.directionAngle"}}):_vm._e()],2):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }