import { fillInForm } from '@/util/form';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import { OBJECT_LOCALIZATION, OBJECT_STATUS_MAP } from '@/constants/object';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    usersListKey: {
      type: Number,
      default: 1,
    },
    content: {
      type: Object,
    },
    brand: {
      type: Object,
      default: () => ({}),
    },
    clear: Boolean,
    isLoading: Boolean,
    coordinates: {
      type: Object,
      default: null,
    },
    inOffer: {
      type: Boolean,
      default: false,
    },
    offerReport: {
      type: String,
      default: '',
    },
    enableObjectTypeUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultFormValues: {
        address: '',
        city: '',
        monthlyPrice: 0,
        postalCode: null,
        directionAngle: null,
      },
      form: {},
      placeChanged: false,
      videoUrl: '',
      imageUrl: '',
      selectedPlace: null,
      isActive: true,
      objectTypeForm: null,
      internalStatus: '',
      localization: '',
      selectedObjectType: null,
    };
  },
  computed: {
    ...mapState('object', ['objectType']),
    ...mapState('client', {
      client: 'details',
    }),
    onProperty() {
      return [
        OBJECT_LOCALIZATION.IN_BUILDING,
        OBJECT_LOCALIZATION.ON_BUILDING,
        OBJECT_LOCALIZATION.NEXT_TO_BUILDING,
      ].includes(this.localization);
    },
    formData() {
      return {
        ...this.form,
        ...(this.imageUrl && { imageUrl: this.imageUrl }),
        ...(this.videoUrl && { videoUrl: this.videoUrl }),
        placeId: this.selectedPlace?.id,
        objectTypeId: this.objectType?.id,
        ...this.objectTypeForm,
        internalStatus: this.inOffer
          ? OBJECT_STATUS_MAP.IN_OFFER
          : this.internalStatus?.value,
        ...this.coordinates,
        localization: this.localization,
        ...(this.selectedObjectType.id !== this.objectType?.id && {
          objectTypeId: this.selectedObjectType.id,
        }),
      };
    },
  },
  watch: {
    clear(val) {
      if (val) this.resetForm();
    },
    localization: {
      immediate: true,
      handler(newLocalization, oldLocalization) {
        this.$emit(
          'on-show-map',
          newLocalization === OBJECT_LOCALIZATION.OUTSIDE,
        );
        if (
          newLocalization === OBJECT_LOCALIZATION.OUTSIDE &&
          oldLocalization
        ) {
          this.form.address = '';
          this.form.city = '';
          this.form.postalCode = null;
        }
      },
    },
    content: {
      immediate: true,
      handler(data) {
        if (!data) this.form = { ...this.defaultFormValues };
        else {
          const {
            place,
            imageUrl,
            videoUrl,
            internalStatus,
            localization,
            tags,
          } = data;
          this.imageUrl = imageUrl || '';
          this.videoUrl = videoUrl || '';
          this.selectedPlace = place || null;
          this.internalStatus = internalStatus;
          this.localization = localization;
          this.tags = tags;
          const newContent = fillInForm(this.defaultFormValues, data);

          this.form = {
            ...this.form,
            ...newContent,
          };
        }
      },
    },
    form: {
      handler() {
        this.$emit('form-updated', this.form);
      },
      deep: true,
      immediate: true,
    },
    onProperty(value) {
      if (value && this.selectedPlace) {
        this.fillAddress();
      }
    },
    selectedPlace(place) {
      if (place) {
        const { lat, lng } = place;
        this.$emit('set-place-coordinates', { lat, lng });
      }

      if (this.onProperty) this.fillAddress();
    },
    selectedObjectType: {
      immediate: true,
      handler(objectType) {
        this.$emit('set-object-type', objectType);
      },
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  mounted() {
    this.selectedObjectType = this.objectType ? { ...this.objectType } : null;
  },
  methods: {
    pushFormData() {
      this.$emit('on-submit', this.formData, {
        tags: this.tags,
        tagsToSave: this.tagsUploadQueue,
        tagsToDelete: this.tagsDeleteQueue,
      });
    },
    resetForm() {
      this.form = { ...this.defaultFormValues };
      this.imageUrl = '';
      this.videoUrl = '';
      this.selectedPlace = null;
      this.internalStatus = '';
      this.localization = '';
      this.tags = [];
      this.selectedObjectType = { ...this.objectType };
      this.directionAngle = null;

      this.$nextTick(() => {
        const { form } = this.$refs;
        if (form) form.reset();
      });
    },
    fillAddress() {
      const { address, city, postalCode, lat, lng } =
        this.selectedPlace || this.defaultFormValues;

      this.form = {
        ...this.form,
        address,
        city,
        postalCode,
      };

      if (typeof lat === 'number' && typeof lng === 'number') {
        this.$emit('set-coordinates', { lat, lng });
      }
    },
  },
};
