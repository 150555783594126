import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { computed, ref } from '@vue/composition-api';
import { REPORT_STATUS_COLORS } from '@/constants/report';
import { Access } from '@/constants/access';
import { ReportStatus } from '@/api/requests/reports';
const { useGetters: useUserGetters } = createNamespacedHelpers('user');
const { useActions: useApplicationActions } = createNamespacedHelpers('applications');
// TODO: handle permissions and report statuses
export const useReportStatus = (ctx) => {
    const { hasAdminRights, isAuthorizedToMakeChanges } = useUserGetters([
        'hasAdminRights',
        'isAuthorizedToMakeChanges',
    ]);
    const { editReport } = useApplicationActions(['editReport']);
    const statusConfig = computed(() => {
        return {
            translation: 'report-status',
        };
    });
    const accessLevel = computed(() => {
        if (hasAdminRights.value)
            return Access.ADMIN;
        return Access.DEFAULT;
    });
    const getStatusOptions = (currentStatus) => {
        const statuses = [ReportStatus.ACTIVE, ReportStatus.FINISHED];
        return statuses.filter((status) => status !== currentStatus);
    };
    const forceUpdateKey = ref(0);
    const changeReportStatus = async (reportId, status) => {
        await editReport({
            data: {
                status,
            },
            reportId,
        });
        forceUpdateKey.value++;
    };
    const getActions = (row) => {
        const { status, creator } = row;
        const isActive = status === ReportStatus.ACTIVE;
        const editable = isActive;
        const archive = {
        // TODO
        };
        const finish = {
            finish: (id) => changeReportStatus(id, ReportStatus.FINISHED),
        };
        const edit = { edit: (id) => ctx.emit('edit', id) };
        const actionMap = {
            [Access.ADMIN]: {
                ...archive,
                ...(editable && edit),
                ...(isActive && finish),
            },
            [Access.DEFAULT]: {
                ...(isAuthorizedToMakeChanges.value([creator.id]) && editable && edit),
            },
        };
        return actionMap[accessLevel.value];
    };
    return {
        accessLevel,
        REPORT_STATUS_COLORS,
        getStatusOptions,
        statusConfig,
        forceUpdateKey,
        getActions,
        changeReportStatus,
    };
};
